



































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { ReportsModule } from '@/store/index';
import { ReportsType, ReportsListType } from '../models';
import ReportsRRModal from './ReportsRRModal.vue';
import ReportsEmailModal from './ReportsEmailModal.vue';
import WoBaseModal from '@/pages/Main/components/WoBaseModal.vue';
import TmsTable from '@/shared/components/table/TmsTable.vue';

import {
  CellContentType,
  TableHeaders
} from '@/shared/components/table/models';

@Component({
  components: {
    ReportsRRModal,
    ReportsEmailModal,
    WoBaseModal,
    TmsTable
  }
})
export default class EarlyWarningReport extends Vue {
  @Prop() filters: any;

  @Watch('$route', { immediate: true })
  onRouterChange() {
    this.reportType = this.$route.params.type.toUpperCase();
  }

  tabIndex = 0;
  contextMenuOptions = [
    {
      name: this.$i18n.t('contextMenu.detailInfo'),
      slug: 'detail-info-modal',
      index: 0
    },
    {
      name: this.$i18n.t('contextMenu.billingInfo'),
      slug: 'billing-info-modal',
      index: 1
    },
    {
      name: this.$i18n.t('contextMenu.document'),
      slug: 'document-modal',
      index: 2
    },
    {
      name: this.$i18n.t('contextMenu.memo'),
      slug: 'memo-modal',
      index: 3
    },
    {
      name: this.$i18n.t('contextMenu.tracking'),
      slug: 'tracking-modal',
      index: 4
    }
  ];

  reportsModule = ReportsModule;
  reportType = '';
  selectedListItem = {};
  emailModalData = [];

  constructor() {
    super();
  }

  get selectedReportsList(): TableHeaders[] {
    return ReportsListType[ReportsType[this.reportType]];
  }

  get headersList() {
    return [...this.selectedReportsList];
  }

  get tableData() {
    return this.reportsModule.earlyWarning;
  }

  onAction({ key, data }: { key: string; data: any }) {
    this.openReportDetailModal(data);
  }

  rowRightClicked(item: any, index, event) {
    event.preventDefault();
    const contextMenu: any = this.$refs.contextMenu;
    contextMenu.showMenu(event, item);
  }

  onContextMenuOptionSelect(event: {
    item: any;
    option: { name: string; slug: string; index: number };
  }) {
    this.openReportDetailModal(event.item, event.option.index);
  }

  openReportDetailModal(listItem, index = 0) {
    this.selectedListItem = listItem;
    this.tabIndex = index;
    this.$bvModal.show('wo-base-modal');
  }
}




































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { ReportsModule } from '@/store/index';
import { ReportsType, ReportsListType } from '../models';
import ReportsRRModal from './ReportsRRModal.vue';
import ReportsEmailModal from './ReportsEmailModal.vue';
import WoBaseModal from '@/pages/Main/components/WoBaseModal.vue';
import TmsTable from '@/shared/components/table/TmsTable.vue';

import { reportsBus } from '../Reports.vue';

import { convertAPIToViewFullFormat } from '@/utils/date.util';
import {
  CellContentType,
  TableHeaders
} from '@/shared/components/table/models';
import { PermissionsService } from '@/shared/services/permission/permission.service';

@Component({
  components: {
    ReportsRRModal,
    ReportsEmailModal,
    WoBaseModal,
    TmsTable
  }
})
export default class ListReport extends Vue {
  @Prop() filters: any;

  @Watch('$route', { immediate: true })
  onRouterChange() {
    this.reportType = this.$route.params.type.toUpperCase();
  }

  @Watch('selectedListItems')
  onSelectedListItemsUpdate() {
    this.reportsModule.permitSendingEmails(!!this.selectedListItems.length);
  }

  @Watch('isLoading') onLoading() {
    if (this.isLoading) {
      this.selectedListItems = [];
    }
  }

  tabIndex = 0;
  contextMenuOptions = [
    {
      name: this.$i18n.t('contextMenu.detailInfo'),
      slug: 'detail-info-modal',
      index: 0
    },
    {
      name: this.$i18n.t('contextMenu.billingInfo'),
      slug: 'billing-info-modal',
      index: 1
    },
    {
      name: this.$i18n.t('contextMenu.document'),
      slug: 'document-modal',
      index: 2
    },
    {
      name: this.$i18n.t('contextMenu.memo'),
      slug: 'memo-modal',
      index: 3
    },
    {
      name: this.$i18n.t('contextMenu.tracking'),
      slug: 'tracking-modal',
      index: 4
    }
  ];

  reportsModule = ReportsModule;
  reportType = '';
  disabled = false;
  selectedListItem = {};
  emailModalData = [];
  selectedListItems = [];

  constructor() {
    super();
  }

  created() {
    reportsBus.$on('sendEmail', data => {
      this.openEmailModal([...this.selectedListItems]);
    });

    this.disabled = PermissionsService.can('TMS REPORT', 'DEM/PER', 'View');
  }

  get isLoading() {
    return this.reportsModule.loading;
  }

  get selectedReportsList(): TableHeaders[] {
    return ReportsListType[ReportsType[this.reportType]];
  }

  get headersList() {
    const extendeHeadersList: TableHeaders[] = [...this.selectedReportsList];
    extendeHeadersList.push({
      key: 'email',
      label: '',
      content: {
        type: CellContentType.Button,
        label: 'Email'
      }
    });

    extendeHeadersList.unshift({
      key: 'details',
      label: '',
      content: {
        type: CellContentType.Button,
        label: 'Details'
      }
    });
    extendeHeadersList.splice(1, 0, { key: 'number', label: 'NO' });
    extendeHeadersList.splice(1, 0, {
      key: 'check',
      label: '',
      content: { type: CellContentType.Checkbox }
    });

    return extendeHeadersList;
  }

  get tableData() {
    const list = Array.isArray(this.reportsModule.reports)
      ? this.reportsModule.reports
      : [];

    switch (this.reportType.toLowerCase()) {
      case ReportsType.DEMURRAGE:
        return list.map(this.mapDemurrage);
      case ReportsType.PERDIEM:
        return list.map(this.mapPerdiem);
      default:
        return list;
    }
  }

  inputChange({ field, data }) {
    switch (field) {
      case 'check':
        this.toggleCheck(data);
        break;
    }
  }

  onAction({ key, data }: { key: string; data: any }) {
    switch (key) {
      case 'details':
        this.openReportDetailModal(
          data.originReport ? data.originReport : data
        );
        break;
      case 'email':
        this.openEmailModal([data.originReport ? data.originReport : data]);
        break;
      default:
        this.openRRModal(data.originReport ? data.originReport : data);
    }
  }

  toggleCheck(listItem) {
    if (listItem.check) {
      this.selectedListItems.push(listItem.originReport);
    } else {
      const index = this.selectedListItems
        .map(item => item.orderNumber)
        .indexOf(listItem.orderNumber);

      if (index !== -1) {
        this.selectedListItems.splice(index, 1);
      }
    }

    const billToSet = new Set(this.selectedListItems.map(item => item.billTo));
    const selectedItemsOrderNumberSet = new Set(
      this.selectedListItems.map(item => item.orderNumber)
    );

    const newReports = this.tableData.map(element => {
      const isDisabled = !!billToSet.size && !billToSet.has(element.billTo);

      return {
        ...element.originReport,
        ...{
          disableFields: { check: isDisabled },
          check: selectedItemsOrderNumberSet.has(element.orderNumber)
        }
      };
    });

    this.reportsModule.setReports(newReports);
  }

  openRRModal(listItem) {
    this.selectedListItem = listItem;
    this.$bvModal.show('reports-rr-modal');
  }

  openEmailModal(listItems) {
    this.emailModalData = [...listItems];
    this.$bvModal.show('reports-email-modal');
  }

  rowRightClicked(item: any, index, event) {
    event.preventDefault();
    const contextMenu: any = this.$refs.contextMenu;
    contextMenu.showMenu(event, item.originReport);
  }

  async updateRR() {
    await this.reportsModule.search();
  }

  onContextMenuOptionSelect(event: {
    item: any;
    option: { name: string; slug: string; index: number };
  }) {
    this.openReportDetailModal(event.item, event.option.index);
  }

  openReportDetailModal(listItem, index = 0) {
    this.selectedListItem = listItem;
    this.tabIndex = index;
    this.$bvModal.show('wo-base-modal');
  }

  private mapDemurrage(report: { [key: string]: any }) {
    return {
      originReport: report,
      ...report,
      ...{
        lastFreeDay: report.lastFreeDay
          ? convertAPIToViewFullFormat(report.lastFreeDay)
          : report.lastFreeDay,
        pulloutSchedule: report.pulloutSchedule
          ? convertAPIToViewFullFormat(report.pulloutSchedule)
          : report.pulloutSchedule,
        avaDsc: report.avaDsc ? 'Y' : 'N',
        avaObl: report.avaObl ? 'Y' : 'N',
        avaCus: report.avaCus ? 'Y' : 'N',
        avaOth: report.avaOth ? 'Y' : 'N'
      }
    };
  }

  private mapPerdiem(report: { [key: string]: any }) {
    return {
      originReport: report,
      ...report,
      ...{
        returnFreeDay: report.returnFreeDay
          ? convertAPIToViewFullFormat(report.returnFreeDay)
          : report.returnFreeDay,
        returnSchedule: report.returnSchedule
          ? convertAPIToViewFullFormat(report.returnSchedule)
          : report.returnSchedule
      }
    };
  }
}

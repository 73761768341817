

























import { Vue, Component, Prop } from 'vue-property-decorator';

import { ReportsType } from '../models';
import { ReportsModule } from '@/store/index';
import TmsTable from '@/shared/components/table/TmsTable.vue';

import {
  convertAPIToFormat,
  convertAPIToViewFullFormat
} from '@/utils/date.util';

@Component({ components: { TmsTable } })
export default class ScheduleListModal extends Vue {
  @Prop({ default: [] }) headersList: [{ key: string; label: string }];
  @Prop({ default: [] }) ordersList;
  @Prop() selectedDate;

  constructor() {
    super();
  }

  get tableData() {
    return (this.ordersList || []).map((order, index) => {
      const copyOfOrder = { ...order, no: index + 1 };

      if (copyOfOrder.lastFreeDay) {
        copyOfOrder.lastFreeDay = convertAPIToViewFullFormat(
          copyOfOrder.lastFreeDay
        );
      }

      if (copyOfOrder.returnFreeDay) {
        copyOfOrder.returnFreeDay = convertAPIToViewFullFormat(
          copyOfOrder.returnFreeDay
        );
      }

      if (copyOfOrder.cutOff) {
        copyOfOrder.cutOff = convertAPIToViewFullFormat(copyOfOrder.cutOff);
      }

      if (copyOfOrder.erd) {
        copyOfOrder.erd = convertAPIToViewFullFormat(copyOfOrder.erd);
      }

      if (copyOfOrder.pulloutSchedule) {
        copyOfOrder.pulloutSchedule = convertAPIToViewFullFormat(
          copyOfOrder.pulloutSchedule
        );
      }

      if (copyOfOrder.returnSchedule) {
        copyOfOrder.returnSchedule = convertAPIToViewFullFormat(
          copyOfOrder.returnSchedule
        );
      }

      if (copyOfOrder.deliverySchedule) {
        copyOfOrder.deliverySchedule = convertAPIToFormat(
          copyOfOrder.deliverySchedule,
          'MM/DD/YY hh:mm'
        );
      }

      if (typeof copyOfOrder.avaDsc === 'boolean') {
        copyOfOrder.avaDsc ? 'Y' : 'N';
      }

      if (typeof copyOfOrder.avaObl === 'boolean') {
        copyOfOrder.avaObl ? 'Y' : 'N';
      }

      if (typeof copyOfOrder.avaСus === 'boolean') {
        copyOfOrder.avaСus ? 'Y' : 'N';
      }

      if (typeof copyOfOrder.tmf === 'boolean') {
        copyOfOrder.tmf ? 'Y' : 'N';
      }

      return copyOfOrder;
    });
  }

  async excel() {
    await ReportsModule.excel(
      ReportsType[this.$route.params.type.toUpperCase()]
    );

    if (ReportsModule.excelData) {
      window.open(ReportsModule.excelData.url);
    }
  }
}

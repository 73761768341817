






















import { Component, Vue, Watch } from 'vue-property-decorator';

import {
  ReportsFilters,
  CalendarReport,
  ListReport,
  StatisticsReport,
  EarlyWarningReport
} from './components';

import { ReportsType } from './models';
import { ReportsModule } from '@/store/index';
import Loading from '@/shared/components/Loading.vue';

export const reportsBus = new Vue();

@Component({
  components: {
    calendar: CalendarReport,
    list: ListReport,
    statistics: StatisticsReport,
    ReportsFilters,
    EarlyWarningReport,
    Loading
  }
})
export default class Reports extends Vue {
  @Watch('$route', { immediate: true })
  onRouterChange() {
    const type = this.$route.params.type.toUpperCase();
    this.setSelectedReportsType(ReportsType[type]);
  }

  selectedReportType = '';
  reportsModule = ReportsModule;
  reportFilters = [];

  constructor() {
    super();
  }

  setSelectedReportsType(type) {
    switch (type) {
      case ReportsType.PULLOUT:
      case ReportsType.DELIVERY:
      case ReportsType.RETURN: {
        this.selectedReportType = 'calendar';
        break;
      }
      case ReportsType.DEMURRAGE:
      case ReportsType.PERDIEM: {
        this.selectedReportType = 'list';
        break;
      }
      case ReportsType.STATISTICS: {
        this.selectedReportType = 'statistics';
        break;
      }
      case ReportsType['EARLY-WARNING']: {
        this.selectedReportType = 'EarlyWarningReport';
        break;
      }
    }
  }
}
